<template>
  <van-skeleton v-if="loading" :row="6" />
  <div v-else class="page">
    <NameHead :name_info="name_info" :can_click="false" />
    <div class="mod3" v-if="name_info.lis_juzi.length" >
      <p class="title">相关诗句</p>
      <div v-for="(item, index) in name_info.lis_juzi" :key="index"  class="shiju"  :data-value="item.id" @click="get_poe_detail" >
        <div class="left" v-html="item.warn_p" ></div>
        <div class="right"><van-icon name="ellipsis" color="#ccc" size="0.45rem" /></div>
      </div>
    </div>
    <div class="mod3"  v-if="name_info.lis_cy.length" >
      <p class="title">相关成语</p>
      <div v-for="(item, index) in name_info.lis_cy"  :key="index" class="chengyu" >
        <div class="title2"  v-html="item.warn_p" ></div>
        <span class="desc" >{{item.desc?item.desc:'-'}}</span>
      </div>
    </div>
    <div class="mod3"  v-if="name_info.lis_people.length" >
      <p class="title">名人明星</p>
      <div  v-for="(item, index) in name_info.lis_people"  :key="index" class="people" >
        <div class="title2"  v-html="item.warn_p" ></div>
        <span class="desc" >{{item.desc?item.type + '-' +item.desc:item.type}}</span>
      </div>
    </div>
    <div class="mod3">
      <p class="title">发音分析</p>
      <div id="real_pinyin">
        <div v-for="(pinyin, index) in name_info.pinyin" :key="index" class="pinyin" >
          <p><span  v-for="(yin, j) in pinyin.yin" :key="j">{{yin}} </span></p>
          <p >{{pinyin.word}}</p>
        </div>
      </div>
      <div v-for="(item, index) in name_info.shengyin" :key="index">
        <p  class="yin" v-if=" item.open || yin_more ">
          <span class="title2">{{item.type}}</span>
          <span class="desc">{{ item.desc }}</span>
        </p>
      </div>
      <p v-if="!yin_more" class="tips"  @click="zhankai_yin">展开更多</p>
    </div>
    <div  class="mod3" id="sex">
      <p class="title" >性别分析</p>
      <div>
        <p id="circle" :style= "'background-image:linear-gradient(to right,lightblue '+ name_info.sex_low +'%, lightpink ' +  name_info.sex_up + '%)'"><span></span></p>
        <p id="sex_desc" >
          <span v-if="name_info.sex>90">男性：以往占比{{name_info.sex}}%</span>
          <span v-else-if="name_info.sex>60">偏男：以往占比{{name_info.sex}}%</span>
          <span v-else-if="name_info.sex>40">男女均可</span>
          <span v-else-if="name_info.sex>30">偏女：以往占比{{100-name_info.sex}}%</span>
          <span v-else>女性：以往占比{{100-name_info.sex}}%</span>
        </p>
      </div>
    </div>

    <div class="mod3">
      <p class="title">笔画分析</p>
      <div id="bihua_desc">
        <span>共</span>
        <span id="bihua_num">{{name_info.bihua.num}}画</span>
        <p v-if="name_info.bihua.pst<80">
          <span >，简洁程度高于</span>
          <span class="bihua_pst">{{100 - name_info.bihua.pst}}%</span>
        </p>
        <p v-else>
          <span >，简洁程度低于</span>
          <span class="bihua_pst">{{name_info.bihua.pst}}%</span>
        </p>
        <span>的人</span>
      </div>
    </div>
    <div class="mod3">
      <p class="title">字含义</p>
      <div v-for="(zi,i) in name_info.mean" :key="i">
        <p class="zi">-- {{ zi.word }} --</p>

        <div v-for="(yin, k) in  zi.mean" :key="k">
          <p v-if="yin.pinyin" class="mean_yin tag" >{{yin.pinyin}}</p>
          <div v-for="(mean, j) in yin.mean"  :key="j">
            <p v-if="zi.open || j < 5 "  class="one_mean">
              <span class="desc2"> {{ mean.desc }}</span>
              <span class="ex2"> {{ mean.ex }} </span>
            </p>
          </div>

        </div>
        <p v-if="zi.length >5 && !zi.open" class="tips" :data-value="i" @click="zhankai_mean">更多含义</p>
        <p>
          <span  class="tag2" @click="up_chengyu" :data-value="zi.word" > {{zi.word}}字成语 </span>
          <span  class="tag2" @click="up_ciyu" :data-value="zi.word"> 高频词语 </span>
          <span  class="tag2" @click="up_people" :data-value="zi.word"> 名人明星 </span>
        </p>
      </div>
    </div>
<!--    <div class="mod3">-->
<!--      <p class="title">五行分析</p>-->
<!--      <p v-for="(wuxing, index) in name_info.wuxing " :key="index" class="wuxing" >-->
<!--        <span v-if="wuxing.wx==='金'" class="b jin">{{wuxing.wx?wuxing.wx:'-'}}</span>-->
<!--        <span v-else-if="wuxing.wx==='木'" class="b mu">{{wuxing.wx?wuxing.wx:'-'}}</span>-->
<!--        <span v-else-if="wuxing.wx==='水'" class="b shui">{{wuxing.wx?wuxing.wx:'-'}}</span>-->
<!--        <span v-else-if="wuxing.wx==='火'" class="b huo">{{wuxing.wx?wuxing.wx:'-'}}</span>-->
<!--        <span v-else-if="wuxing.wx==='土'" class="b tu">{{wuxing.wx?wuxing.wx:'-'}}</span>-->
<!--        <span v-else class="b mu">{{wuxing.wx?wuxing.wx:'-'}}</span>-->

<!--        <span class="light">{{wuxing.word}}</span>-->
<!--      </p>-->
<!--    </div>-->

    <div class="mod3" v-if="!name_info.lis_people.length">
      <p class="title">未发现相关名人明星</p>

    </div>
    <div class="mod3" v-if="!name_info.lis_cy.length">
      <p class="title">未发现相关成语</p>
    </div>

    <div class="mod3" v-if="!name_info.lis_juzi.length" >
      <p class="title">未发现相关诗句</p>
    </div>

    <div class="mod3" >
      <!--          ◇-->
      <p class="title">更多相关名字</p>
      <div v-if="name_info.word.length===3" class="more_name" :data-value=" ['在后', name_info.word[2] ] " @click="name_to_fit">
        <div class="left">
          <span class='b'>{{ name_info.word[0] }}</span>
          <span class='b warn'>__</span>
          <span class='b'>{{name_info.word[2]}}</span>
        </div>
        <div class="right">
          <van-icon name="arrow" color="#ccc" size="0.45rem" />
        </div>
      </div>
            <div v-if="name_info.word.length===3" class="more_name" :data-value=" ['在前', name_info.word[2] ] " @click="name_to_fit">
        <div class="left">
          <span class='b'>{{ name_info.word[0] }}</span>
          <span class='b'>{{name_info.word[2]}}</span>
          <span class='b warn'>__</span>
        </div>
        <div class="right">
          <van-icon name="arrow" color="#ccc" size="0.45rem" />
        </div>
      </div>
      <div v-if="name_info.word.length===3"  class="more_name" :data-value="['在前',name_info.word[1]]" @click="name_to_fit">
        <div class="left">
          <span class='b'>{{ name_info.word[0]}}</span>
          <span class='b'>{{name_info.word[1]}}</span>
          <span class='b warn'>__</span>
        </div>
        <div class="right">
          <van-icon name="arrow" color="#ccc" size="0.45rem" />
        </div>
      </div>
      <div v-if="name_info.word.length===3"  class="more_name" :data-value="['在后',name_info.word[1]]" @click="name_to_fit">
        <div class="left">
          <span class='b'>{{ name_info.word[0]}}</span>
          <span class='b warn'>__</span>
          <span class='b'>{{name_info.word[1]}}</span>
        </div>
        <div class="right">
          <van-icon name="arrow" color="#ccc" size="0.45rem" />
        </div>
      </div>
      <div v-if="name_info.word.length===2"  class="more_name" :data-value="['在后', name_info.word[1]]" @click="name_to_fit">
        <div class="left">
          <span class='b'>{{ name_info.word[0]}}</span>
          <span class='b warn'>__</span>
          <span class='b'>{{name_info.word[1]}}</span>
        </div>
        <div class="right">
          <van-icon name="arrow" color="#ccc" size="0.45rem" />
        </div>
      </div>
      <div v-if="name_info.word.length===2"  class="more_name" :data-value="['在前',name_info.word[1]]" @click="name_to_fit">
        <div class="left">
          <span class='b'>{{ name_info.word[0]}}</span>
          <span class='b'>{{name_info.word[1]}}</span>
          <span class='b warn'>__</span>
        </div>
        <div class="right">
          <van-icon name="arrow" color="#ccc" size="0.45rem" />
        </div>
      </div>
    </div>

    <button v-if="!can_see_more"   class="to_be_vip" data-value="name_detail|bottom" @click="xml_go_to_goods_index">了解更多大数据起名功能</button>

    <div class="foot" >
      <button :class="mark ? 'w1 background_' + name_info.color :'black w1'" :disabled="disabled" @click="mark_name_or_not" >{{ mark?'取消收藏':'收 藏'  }}</button>
    </div>
    <ul id="last_right_b" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>

    <!-- poe_detail -->
    <van-popup v-model:show="show.poe" round position="bottom" :style="{ height: '90%' }" @close="on_close">
     <van-skeleton v-if="loading2" :row="6" />
      <div v-else>
        <div class="head">
          <div class="title">
            <p class="title2">{{current_poe.title}}</p>
          </div>
          <div class="about">
            <div class="l1">
              <p>{{current_poe.dynasty}} -- {{current_poe.author}}</p>
            </div>
          </div>
        </div>

        <div  v-for="(juzi,index) in current_poe.body" :key="index" class="shiju2">
          {{juzi}}
        </div>
        <SearchName></SearchName>
      </div>
    </van-popup>

    <van-popup v-model:show="show.chengyu" round position="bottom" :style="{ height: '90%' }" @close="on_close">
      <ListChengyu :word="select_word" tag="zi" from_page="name_detail" :can_see_more="can_see_more"  v-if="renderComponent" />
    </van-popup>

    <van-popup v-model:show="show.people" round position="bottom" :style="{ height: '90%' }" @close="on_close">
      <ListPeople :word="select_word" tag="zi" from_page="name_detail" :can_see_more="can_see_more"  v-if="renderComponent" />

    </van-popup>

    <van-popup v-model:show="show.ciyu" round position="bottom" :style="{ height: '90%' }" @close="on_close">
      <ListCiyu :word="select_word" tag="zi" from_page="name_detail" :can_see_more="can_see_more"  v-if="renderComponent" />
    </van-popup>

    <van-popup v-model:show="show.zc" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <Login ></Login>
    </van-popup>
  </div>
</template>

<script>

import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'
import router from '@/router/index.js'
import NameHead from '@/components/items/NameHead'
import Login from '@/components/other/Login'
import { Toast } from 'vant'
import ListCiyu from '@/components/other/ListCiyu'
import ListChengyu from '@/components/other/ListChengyu'
import ListPeople from '@/components/other/ListPeople'

export default {
  name: 'name_detail',
  store,
  components: {
    Login, NameHead, ListCiyu, ListChengyu, ListPeople
  },
  data () {
    return {
      loading: true,
      loading2: false,
      renderComponent: false,
      base_img: store.state.base_img,
      niming: store.state.niming,
      name: '',
      name_info: {
        tag_list: [],
        desc: [],
        mean: [],
        lis_cy: [],
        lis_people: [],
        bihua: {}
      },
      yin_more: false,
      mark: false,
      can_see_more: false,
      page: { chengyu: 1, ciyu: 1, people: 1 },
      show: { chengyu: false, ciyu: false, people: false, poe: false, zc: false },
      disabled: false,
      select_word: '',
      current_poe: {},
      name_str: ''
    }
  },
  // shengyin: { duoyin: {'双多音': {}, '多音字': {} }, base: {韵律: {}, 同音: {}, 连读: {}, 连生母: {}, 连韵母: {} } },
  mounted () {
    this.name_detail()
  },

  methods: {
    name_detail () {
      axios.post(this.niming ? '/name_detail_unlogin/' : '/name_detail/', { name: this.$route.query.name })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          console.log(res.data)
          this.name_info = res.data.data.name_info
          this.mark = res.data.data.mark
          this.can_see_more = res.data.data.can_see_more
          this.loading = false
          Utils.alert_tip_list('name_detail', res.data.data.tip_list)
        })
    },
    mark_name_or_not (e) {
      if (this.niming) {
        this.show.zc = true
        return
      }
      Utils.show_loading()
      this.disabled = true
      axios.post('/mark_name_or_not/', { name: this.name_info.word })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.disabled = false
          this.mark = res.data.data.mark
          Toast.clear()
        })
    },
    zhankai_mean (e) {
      this.name_info.mean[e.currentTarget.dataset.value].open = true
    },
    zhankai_yin (e) {
      this.yin_more = true
    },
    name_to_fit (e) {
      if (this.niming) {
        this.show.zc = true
        return
      }
      const tt = e.currentTarget.dataset.value.split(',')
      router.push({ name: 'name_fit', query: { xing: this.name_info.word[0], word: tt[1], use_way: JSON.stringify({ cixing: '全部', fit_place: tt[0], paixu: '默认排序' }) } })
    },
    get_poe_detail (e) {
      if (this.niming) {
        this.show.zc = true
        return
      }
      this.loading2 = true
      this.show.poe = true
      this.current_poe = {}
      axios.post('/poe_detail/', { shiju_id: e.currentTarget.dataset.value })
        .then(res => {
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.current_poe = res.data.data.info
        })
    },

    up_chengyu (e) {
      if (this.niming) {
        this.show.zc = true
        return
      }
      this.renderComponent = true
      this.select_word = e.currentTarget.dataset.value
      this.show.chengyu = true
    },
    up_ciyu (e) {
      if (this.niming) {
        this.show.zc = true
        return
      }
      this.renderComponent = true
      this.select_word = e.currentTarget.dataset.value
      this.show.ciyu = true
    },
    up_people (e) {
      if (this.niming) {
        this.show.zc = true
        return
      }
      this.renderComponent = true
      this.select_word = e.currentTarget.dataset.value
      this.show.people = true
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    on_close (e) {
      this.renderComponent = false
      this.show = { chengyu: false, people: false, ciyu: false, poe: false, zc: false }
    },
    xml_go_to_goods_index (e) {
      if (!localStorage.getItem('token')) {
        this.show.zc = true
        return
      }
      Utils.go_to_goods_index(e)
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    xml_go_to (e) {
      if (!localStorage.getItem('token')) {
        this.show.zc = true
        return
      }
      Utils.go_to(e)
    }
  }
}
</script>

<style scoped>
.page{
  padding-bottom: 3.6rem;
}
.background_color_1{
  background-color:#FFB6C1;
  color: #fefefe;
  border: 0.02rem solid #FFB6C1;
}
.background_color_2{
  background-color: #DBBFD1;
  color: #fefefe;
  border: 0.02rem solid #DBBFD1;
}
.background_color_3{
  background-color: #C1C5DD;
  color: #fefefe;
  border: 0.02rem solid #C1C5DD;
}
.background_color_4{
  background-color: #A6CBE9;
  color: #fefefe;
  border: 0.02rem solid #A6CBE9;
}
.background_color_5{
  background-color: #81D3F8;
  color: #fefefe;
  border: 0.02rem solid #81D3F8;
}
#real_pinyin{
  text-align: center;
}
#sex div p{
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin-left: 0.5rem;
}

#circle{
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 1.8rem;
  position: relative;
  text-align: center;
  vertical-align: middle;
}
#circle span{
  width: 1.4rem;
  height: 1.4rem;
  line-height: 1.4rem;
  border-radius: 1.4rem;
  left: 0.20rem;
  top: 0.20rem;
  position: absolute;
  background-color: #f8f8f8;
  display: block;
  text-align: center;
}
#sex_desc span{
  display: block
}

#bihua_desc{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}
#bihua_desc p{
  margin-top: 0.3rem;
  display: inline-block;
}
#bihua_num{
  color: #9FE6B8;
}
.bihua_pst{
  color: #F59A23;
}

.wuxing,.pinyin{
  width: 2.7rem;
  padding: 0.30rem 0rem;
  display: inline-block;
  text-align: center;
}
.wuxing span{
  display: block;
}

.wuxing p,.pinyin p{
  display: block;
  margin: 0.30rem 0rem;
}
.wuxing .b{
  font-size: 0.8rem;
  font-weight: bold;
}

.chengyu .desc{
  width: 6rem;
  color: #999;
  border-bottom: 1rem dashed #ccc;
}
.yin,.people,.chengyu{
  padding: 0.30rem 0rem;
}
.yin span,.people span,.chengyu span{
  display: inline-block;
  vertical-align: middle;
}
.yin .title2,.people .title2,.chengyu .title2{
  width: 2.5rem;
  display: inline-block;
  text-align: center;
}

.yin .desc,.people .desc,.chengyu .desc{
  width: 6rem;
  display: inline-block;
  color: #999;
  border-bottom: 0.01rem dashed #eee;
  padding: 0.2rem 0rem;
  margin-left: 0.2rem;
}
.yin .desc,.people .desc{
  text-align: center;
}
.jin{
  color: #FFD700;
}
.mu{
  color: #228B22
}
.shui{
  color: #87CEFA
}
.huo{
  color: #FF4500
}
.tu{
  color: #A0522D
}

.shiju{
  margin: 0.4rem 0rem;
  padding: 0.4rem 0.45rem;
  text-align: left;
  background-color: #fefefe;
  border-bottom: 0.02rem dashed #eee;
}
.shiju div{
  display: inline-block;
}
.shiju .left{
  width: 7.2rem;
  vertical-align: middle;
  display: inline-block;
}

.shiju .right{
  width: 0.5rem;
  height: 0.4rem;
  line-height: 0.4rem;
  vertical-align: middle;
  color: lightgray;
  margin-left: 0.4rem;
  display: inline-block;
}

.tag2{
  display: inline-block;
  width: 2.4rem;
  height: 0.80rem;
  line-height: 0.80rem;
  font-size: 0.36rem;
  margin: 0.40rem 0.30rem;
  text-align: center;
  background: rgba(37, 130, 173, 0.5);
  border-radius: 0.40rem;
  color: #fefefe;
}
.name_tag{
  display: inline-block;
  padding: 0.2rem 0.3rem;
  font-size: 0.3rem;
  line-height:  0.3rem;
  margin: 0.20rem 0.2rem 0rem 0.2rem;
  background: rgba(85,85,85,0.18);
  border-radius: 0.30rem;
}
.shiju2{
  margin:0.3rem 0.3rem;
  padding: 0.2rem 0.45rem;
  text-align: center;
  background-color: #fefefe;
}
.head .title2{
  font-size: 0.8rem;
}

.more_name{
  color: #74BCE0;
  margin: 0.4rem 0rem;
  padding: 0.4rem 0.45rem;
  text-align: left;
  background-color: #fefefe;
  border-bottom: 0.02rem dashed #eee;
}

.more_name div{
  display: inline-block;
  vertical-align: middle;
}
.more_name .left{
  width: 7rem;
  text-align: center;
}
.more_name .right img{
  width: 0.4rem;
  height: 0.4rem;
}

</style>
