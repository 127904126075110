<template>
  <van-skeleton v-if="loading" :row="6" />
  <div v-else class="page">
    <button  v-if="!can_see_more" class="to_be_vip"  :data-value="from_page + '|people'" @click="xml_go_to_goods_index">会员可参考数万个历史名人起名</button>
    <ul id="people">
      <li v-for="(item, index) in list" :key="index" :data-value="item.name" @click="go_to_name_detail">
        <div class="word" v-html="item.warn_p"  ></div>
        <div  :class="item.sex===0 ? 'desc word_color_1': item.sex === 1 ? 'desc word_color_5':'desc'" >{{ item.type }}</div>
        <div class="right"><van-icon name="arrow" color="#ccc" size="0.5rem" /></div>
      </li>
    </ul>
    <button v-if="page !== -1" class="in_body black"  @click="get_list">查看更多</button>
    <p v-else class="end">-- End --</p>
  </div>
</template>

<script>

import Utils from '@/utils/utils'
import axios from 'axios'
import router from '@/router/index.js'

export default {
  name: 'ListPeople',
  props: {
    word: String,
    tag: String,
    from_page: String,
    can_see_more: Boolean
  },
  data () {
    return {
      list: [],
      page: 1,
      loading: true,
      loading2: false
    }
  },
  mounted () {
    this.get_list()
  },
  methods: {
    get_list () {
      if (this.loading2 || this.page === -1) {
        return false
      }
      this.loading2 = true
      const data = { word: this.word, page: this.page, tag: this.tag }
      axios.post('/people_list/', data)
        .then(res => {
          this.loading = false
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          if (res.data.data.need_vip) {
            Utils.show_ask_goods({ page: this.from_page, tag: 'people' })
            return
          }
          const ResList = res.data.data.list
          if (ResList.length === 20) {
            this.list = this.list.concat(ResList)
            this.page = this.page + 1
          } else if (ResList.length !== 0) {
            this.list = this.list.concat(ResList)
            this.page = -1
          } else {
            this.page = -1
          }
        })
    },
    xml_go_to_goods_index (e) {
      Utils.go_to_goods_index(e)
    },
    go_to_name_detail (e) {
      const nameStr = e.currentTarget.dataset.value
      router.push({ name: 'name_detail', query: { name: nameStr } })
    }
  }
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
